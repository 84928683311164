<template>
  <div id="divFeedDetailWrapper" class="content-layout-fixer">
    <div>
      <div v-if="videoURL">
        <BrandVimeoPlayer
          class="video-wrapper"
          :id="videoURL"
          ref="vimeoPlayer"
          :data="{ embedVideo: videoURL }"
        >
        </BrandVimeoPlayer>
      </div>
    </div>
  </div>
</template>

<script>
import BrandVimeoPlayer from '@/components/brand/BrandVimeoPlayer/BrandVimeoPlayer.vue';

export default {
  name: 'TrendyolPlayer',
  components: {
    BrandVimeoPlayer,
  },
  data() {
    return {
      videoURL: '',
    };
  },
  mounted() {
    this.getURL();
  },
  methods: {
    getURL() {},
  },
};
</script>
<style scoped lang="scss">
iframe {
  margin-left: 20px;
  margin-top: 20px;
  @media screen and (max-width: 1024px) {
    height: 53.8vw !important;
  }
}
</style>
